import React, {useEffect, useState} from "react";
import studentsData from "../Resources/Data/students.json"
import "./RandomPick.scss";
import {Pause, Play} from "phosphor-react";

export default function RandomPick() {
    const [name, updateName] = useState<string>("");
    const [started, updateStarted] = useState<boolean>(false);
    const [hashSet, /*updateHashSet*/] = useState<Set<string>>(new Set<string>());

    function randomName() {
        if (started)
            updateName(peekNextPerson());
    }

    function peekNextPerson(): string {
        const availables = studentsData.students.filter(x => !hashSet.has(x));
        return availables[Math.floor(Math.random() * availables.length)];
    }

    function start() {
        // If available students are more than 2/3 of all students
        // Then clear the hashSet make all student available again
        if (hashSet.size > studentsData.students.length * (2 / 3)) {
            hashSet.clear();
        }
        updateStarted(true);
    }

    function stop() {
        updateStarted(false);
    }

    useEffect(() => {
        const timerId = setInterval(randomName, 50);
        // clean up
        return () => clearInterval(timerId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [started]);
    return (
        <div className="pageRandomPick">
            {started ? /* Started */
                (<>
                    <p id="randomName">{name}</p>
                    <button id="btnStop" onClick={() => stop()}>
                        <Pause/>
                    </button>
                </>)
                : /* Not started */
                (<>
                    {name !== '' ? (<p id="randomName">{name}</p>) : null}
                    <button id="btnStart" onClick={() => start()}>
                        <Play/>
                    </button>
                </>)}
        </div>
    );
}