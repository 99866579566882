import React from "react";
import "./About.scss"
import {ReactComponent as Logo} from '../logo.svg';

export default function About() {

    return (
        <div className="pageAbout">
            <Logo id="logo"/>
            <h1>Class Tools 2.1.1</h1>
            <p>Copyright &copy; Daokuan Wu 2021-2022</p>
        </div>
    );
}