import React from 'react';
import './App.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import RandomPick from "./Pages/RandomPick";
import About from "./Pages/About";
import PageNotFound from "./Pages/Specials/PageNotFound";
import Layout from "./Layout";

export default function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<RandomPick/>}/>
                        <Route path="about" element={<About/>}/>
                        (/* 404 Not Found */)
                        <Route path="*" element={<PageNotFound/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}