import React from "react";
import {NavLink, Outlet} from "react-router-dom";
import "./Layout.scss"
import {Crosshair, House, Info} from "phosphor-react";

export default function Layout() {
    return (
        <div className="layout">
            <div className="sideNavBar">
                {/*
                <NavLink to="/"><House/></NavLink>
                <span className="split"/>
                */}
                <NavLink to="/about" data-tooltip="关于"><Info/></NavLink>
                <span className="split"/>
                <NavLink to="/" data-tooltip="随机点名"><Crosshair/></NavLink>
            </div>
            <div className="main" role="main">
                <Outlet/>
            </div>
        </div>
    );
}